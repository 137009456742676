@font-face {
	font-family: 'Nissan';
	src: url(./fonts/nissan/NissanBrand-Light.otf) format("opentype");
	font-weight: 300;
}
@font-face {
	font-family: 'Nissan';
	src: url(./fonts/nissan/NissanBrand-Regular.otf) format("opentype");
	font-weight: 400;
}
@font-face {
	font-family: 'Nissan';
	src: url(./fonts/nissan/NissanBrand-Bold.otf) format("opentype");
	font-weight: 500;
}
@font-face {
	font-family: 'Nissan';
	src: url(./fonts/nissan/NissanBrand-Italic.otf) format("opentype");
	font-style: italic;
}

@font-face {
	font-family: 'Nissan';
	src: url(./fonts/nissan/NissanBrand-Light.otf) format("opentype");
	font-weight: 400;
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@font-face{
	font-family:"Radikal";
	font-weight: 100;
	src:url("./fonts/radikal/1565053/794299cd-255d-4b02-967d-83d2f8204072.eot?#iefix");
	src:url("./fonts/radikal/1565053/794299cd-255d-4b02-967d-83d2f8204072.eot?#iefix") format("eot"),url("./fonts/radikal/1565053/091c2b09-5a28-4c9c-a449-8164f5f9f43a.woff2") format("woff2"),url("./fonts/radikal/1565053/dfd8d47e-158a-4bd3-ac85-6530925516cc.woff") format("woff"),url("./fonts/radikal/1565053/32723447-9318-4b28-a191-dca0a1d99c4c.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 100;
	font-style: italic;
	src:url("./fonts/radikal/1565057/388c2ecc-93c8-46cc-8c1b-a099e682243e.eot?#iefix");
	src:url("./fonts/radikal/1565057/388c2ecc-93c8-46cc-8c1b-a099e682243e.eot?#iefix") format("eot"),url("./fonts/radikal/1565057/5c0e7755-ceb0-4ab8-8f50-9f6a993d40cb.woff2") format("woff2"),url("./fonts/radikal/1565057/c8e1f25b-a90e-46b4-9460-318601067802.woff") format("woff"),url("./fonts/radikal/1565057/cfd7a83d-d6f5-43b2-8a0e-2abd9b7b119c.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 200;
	src:url("./fonts/radikal/1565061/fcf58144-b3fd-4adf-bb54-ab30b3d07b90.eot?#iefix");
	src:url("./fonts/radikal/1565061/fcf58144-b3fd-4adf-bb54-ab30b3d07b90.eot?#iefix") format("eot"),url("./fonts/radikal/1565061/d605ef03-c57e-4ab1-bca4-d0bd0d8378b6.woff2") format("woff2"),url("./fonts/radikal/1565061/444350d5-9c9f-4141-ba7d-88b006dc2a18.woff") format("woff"),url("./fonts/radikal/1565061/712e47b4-837a-47fe-9090-818de49d57f7.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 200;
	font-style: italic;
	src:url("./fonts/radikal/1565065/8187a8bf-1978-417f-9dcb-3349cdc635a5.eot?#iefix");
	src:url("./fonts/radikal/1565065/8187a8bf-1978-417f-9dcb-3349cdc635a5.eot?#iefix") format("eot"),url("./fonts/radikal/1565065/62effb24-466f-4b40-9d60-b9b08fe05e44.woff2") format("woff2"),url("./fonts/radikal/1565065/ff4ef959-8536-4315-b3fa-4d069b99fa5f.woff") format("woff"),url("./fonts/radikal/1565065/dac9355c-46fe-4aa4-b986-ea6dd48f25db.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 300;
	src:url("./fonts/radikal/1565069/cc973fe5-89ff-4b66-b509-4e2a943692d8.eot?#iefix");
	src:url("./fonts/radikal/1565069/cc973fe5-89ff-4b66-b509-4e2a943692d8.eot?#iefix") format("eot"),url("./fonts/radikal/1565069/fffdbcfa-e8c6-4e11-ba97-6203e2d4d44c.woff2") format("woff2"),url("./fonts/radikal/1565069/4829a219-fab2-49d0-8122-6d54f1a55ad8.woff") format("woff"),url("./fonts/radikal/1565069/2bd16705-3f7d-4fbb-9c54-7333d4cf2b2c.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 400;
	src:url("./fonts/radikal/1565077/4a92b229-e7ab-4ad9-8046-64d14c962228.eot?#iefix");
	src:url("./fonts/radikal/1565077/4a92b229-e7ab-4ad9-8046-64d14c962228.eot?#iefix") format("eot"),url("./fonts/radikal/1565077/a7fb05fa-4077-4e56-9dad-4eb799668cf4.woff2") format("woff2"),url("./fonts/radikal/1565077/26b514fb-61b0-4c17-848d-c26b55a567ad.woff") format("woff"),url("./fonts/radikal/1565077/95353f81-6a9e-450c-855d-a3e870057bf3.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 400;
	font-style: italic;
	src:url("./fonts/radikal/1565081/272b16f5-6857-4ccd-b609-42abd9958574.eot?#iefix");
	src:url("./fonts/radikal/1565081/272b16f5-6857-4ccd-b609-42abd9958574.eot?#iefix") format("eot"),url("./fonts/radikal/1565081/688b68e6-67dd-4c6d-bc77-560a01b09266.woff2") format("woff2"),url("./fonts/radikal/1565081/073169a7-5392-45bd-b3d6-7b63b8fb4a0a.woff") format("woff"),url("./fonts/radikal/1565081/a45e420b-4ceb-402d-811c-f51a19389dfb.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 500;
	src:url("./fonts/radikal/1565085/fa6811aa-efcc-4326-87d6-f678cd4dd59d.eot?#iefix");
	src:url("./fonts/radikal/1565085/fa6811aa-efcc-4326-87d6-f678cd4dd59d.eot?#iefix") format("eot"),url("./fonts/radikal/1565085/2d4de76c-07b1-4e77-afa0-f56d9235352a.woff2") format("woff2"),url("./fonts/radikal/1565085/4ab12c92-3da4-43c6-a354-ff8cf40d68e9.woff") format("woff"),url("./fonts/radikal/1565085/8ae8726b-2899-4145-b06d-6089693205cf.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 500;
	font-style: italic;
	src:url("./fonts/radikal/1565089/43973ea2-2609-494f-b798-7193a5e3415e.eot?#iefix");
	src:url("./fonts/radikal/1565089/43973ea2-2609-494f-b798-7193a5e3415e.eot?#iefix") format("eot"),url("./fonts/radikal/1565089/36d5139f-3759-4896-9d90-562ccaa2a140.woff2") format("woff2"),url("./fonts/radikal/1565089/571276cb-e059-47dd-96e4-b10813dca730.woff") format("woff"),url("./fonts/radikal/1565089/4c7c3551-29a2-4707-95a8-6555a6a22e4c.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 600;
	src:url("./fonts/radikal/1565093/6d58fc11-7511-4f51-970f-8448884caa3c.eot?#iefix");
	src:url("./fonts/radikal/1565093/6d58fc11-7511-4f51-970f-8448884caa3c.eot?#iefix") format("eot"),url("./fonts/radikal/1565093/41c03572-788c-4bbb-a231-30089524ee6a.woff2") format("woff2"),url("./fonts/radikal/1565093/2c4a62f1-ec18-44fb-bef1-ce6d65482cce.woff") format("woff"),url("./fonts/radikal/1565093/05bc96b1-fcf9-4132-bd2e-cb5b582e0821.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 600;
	font-style: italic;
	src:url("./fonts/radikal/1565097/d7b5caad-7090-42aa-901d-8816ccc2ed63.eot?#iefix");
	src:url("./fonts/radikal/1565097/d7b5caad-7090-42aa-901d-8816ccc2ed63.eot?#iefix") format("eot"),url("./fonts/radikal/1565097/85248d41-a410-4112-985b-dd115e3de9d0.woff2") format("woff2"),url("./fonts/radikal/1565097/f254ece5-57b0-4fb1-9165-4ffb6b8bf0ad.woff") format("woff"),url("./fonts/radikal/1565097/fbb0abba-a89e-49b5-94c3-0981478c89fa.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 700;
	src:url("./fonts/radikal/1565101/3e17af15-80a8-4a6b-9ec3-2d61eb3285bb.eot?#iefix");
	src:url("./fonts/radikal/1565101/3e17af15-80a8-4a6b-9ec3-2d61eb3285bb.eot?#iefix") format("eot"),url("./fonts/radikal/1565101/d0dfe8c6-e7ba-4b76-895c-67480ced92d0.woff2") format("woff2"),url("./fonts/radikal/1565101/1a26e2c1-0f5a-4731-bb72-43180ecb261f.woff") format("woff"),url("./fonts/radikal/1565101/5697eeeb-8651-4093-aecd-a5049557fcee.ttf") format("truetype");
}
@font-face{
	font-family:"Radikal";
	font-weight: 700;
	font-style: italic;
	src:url("./fonts/radikal/1565105/32bb46b5-d77d-4c55-9c90-113a44433adb.eot?#iefix");
	src:url("./fonts/radikal/1565105/32bb46b5-d77d-4c55-9c90-113a44433adb.eot?#iefix") format("eot"),url("./fonts/radikal/1565105/f878deb3-7e53-4ad8-b9b0-639f5314a267.woff2") format("woff2"),url("./fonts/radikal/1565105/7c791210-255d-4c61-a7ee-39062ac69550.woff") format("woff"),url("./fonts/radikal/1565105/2f1fe4ba-7c10-4963-91b3-fde2d85c324e.ttf") format("truetype");
}
