body {
  margin: 0;
  font-family: Radikal, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-color: black;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.005); }
  100% { transform: scale(1); }
}